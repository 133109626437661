import type { IRequestOptions } from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.ts';
import type { WhereExpression } from 'o365.pwa.modules.shared.dexie.WhereExpression.ts';

export type ApiDataOperation = 'retrieve' | 'rowcount' | 'create' | 'update' | 'destroy';

export interface IApiDataRequestOptions<T extends IApiDataRequestBody> {
    appId: string;
    body: T;
    headers: Map<string, Set<string>>;
}

export interface IApiDataRequestBody {
    operation: ApiDataOperation;
    dataObjectId: string;
    viewName: string;
    uniqueTable?: string;
    appIdOverride?: string;
    databaseIdOverride?: string;
    objectStoreIdOverride?: string;
    [key: string]: unknown;
}

export interface IApiDataRequestRetrieveOperationBody extends IApiDataRequestBody {
    operation: 'retrieve';
    dataObjectId: string;
    fields: Array<{ name: string }>;
    filterObject?: { [key: string]: unknown };
    whereObject?: { [key: string]: unknown };
    masterDetailObject: { [key: string]: unknown };
    indexedDbWhereExpression?: WhereExpression;
    skip: number;
    maxRecords: number;
    distinctRows?: boolean;
}
export interface IApiDataRequestCreateOperationBody extends IApiDataRequestBody {
    operation: 'create';
    fields: Array<{ name: string }>;
    values: { [key: string]: unknown };
    bulk?: boolean;
}

export interface IApiDataRequestUpdateOperationBody extends IApiDataRequestBody {
    operation: 'update';
    fields: Array<{ name: string }>;
    values: { [key: string]: unknown };
    bulk?: boolean;
}

export interface IApiDataRequestDestroyOperationBody extends IApiDataRequestBody {
    operation: 'destroy';
    values: { [key: string]: unknown };
    bulk?: boolean;
}
declare class ApiDataRequestOptions<T extends IApiDataRequestBody> implements IRequestOptions {
    public readonly appId: string;
    public readonly body: T;
    public readonly headers: Map<string, Set<string>>;

    constructor(options: IApiDataRequestOptions<T>);

    static fromRequest<T extends IApiDataRequestBody>(request: Request): Promise<ApiDataRequestOptions<T>>;
}

export type { ApiDataRequestOptions };
